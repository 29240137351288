<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <Tab :list="tabList" :active="tabActive" @tabChange="tabChange" />

      <!-- 政策 -->
      <IndexPolicy ref="policyChild" sign="policy" remark="旅游" zcSign="2" v-show="tabActive === 0" />
      <!-- 奖补资金 -->
      <IndexCapital
        ref="capitalChild"
        sign="capital"
        v-show="tabActive === 1"
      />
      <!-- 个体工商户 -->
      <IndividualBusiness
        ref="individualChild"
        sign="individual"
        v-show="tabActive === 2"
      />

      <!-- 底部内容 -->
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "../../components/Footer";
import Tab from "./components/Tab";
// 资金奖补
import IndexCapital from "./indexChild/indexCapital.vue";
// 政策
import IndexPolicy from "./indexChild/indexPolicy";
// 个体工商户
import IndividualBusiness from "./indexChild/indexIndividualBusiness";

export default {
  name: "tourism_environment_index",
  components: { Tab, Footer, IndexPolicy, IndividualBusiness, IndexCapital },
  activated() {},
  data() {
    return {
      // 标签页
      tabList: [
        { name: "政策" },
        { name: "奖补资金" },
        { name: "旅游环境监督" },
      ],
      // 当前选中标签页
      tabActive: 0,
    };
  },
  methods: {
    /**
     * 改变选中tab页
     */
    tabChange(index) {
      console.log(index);
      this.tabActive = index;
    },
  },
};
</script>
<style scoped>
.container {
  /* height: calc(100vh - 97px); */
}
.swiper-box {
  height: 200px;
  background: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
